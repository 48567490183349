@import "./Foundation/variables";
@import "./Foundation/mediaqueries";
@import "./Foundation/fontsize";

@media screen and (min-width: 835px) {
  .mbpc-0 {
    margin-bottom: 0px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-0 {
    margin-bottom: 0px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-1 {
    margin-bottom: 1px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-1 {
    margin-bottom: 1px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-2 {
    margin-bottom: 2px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-2 {
    margin-bottom: 2px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-3 {
    margin-bottom: 3px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-3 {
    margin-bottom: 3px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-4 {
    margin-bottom: 4px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-4 {
    margin-bottom: 4px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-5 {
    margin-bottom: 5px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-5 {
    margin-bottom: 5px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-6 {
    margin-bottom: 6px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-6 {
    margin-bottom: 6px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-7 {
    margin-bottom: 7px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-7 {
    margin-bottom: 7px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-8 {
    margin-bottom: 8px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-8 {
    margin-bottom: 8px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-9 {
    margin-bottom: 9px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-9 {
    margin-bottom: 9px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-10 {
    margin-bottom: 10px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-10 {
    margin-bottom: 10px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-11 {
    margin-bottom: 11px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-11 {
    margin-bottom: 11px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-12 {
    margin-bottom: 12px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-12 {
    margin-bottom: 12px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-13 {
    margin-bottom: 13px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-13 {
    margin-bottom: 13px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-14 {
    margin-bottom: 14px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-14 {
    margin-bottom: 14px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-15 {
    margin-bottom: 15px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-15 {
    margin-bottom: 15px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-16 {
    margin-bottom: 16px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-16 {
    margin-bottom: 16px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-17 {
    margin-bottom: 17px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-17 {
    margin-bottom: 17px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-18 {
    margin-bottom: 18px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-18 {
    margin-bottom: 18px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-19 {
    margin-bottom: 19px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-19 {
    margin-bottom: 19px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-20 {
    margin-bottom: 20px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-20 {
    margin-bottom: 20px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-21 {
    margin-bottom: 21px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-21 {
    margin-bottom: 21px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-22 {
    margin-bottom: 22px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-22 {
    margin-bottom: 22px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-23 {
    margin-bottom: 23px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-23 {
    margin-bottom: 23px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-24 {
    margin-bottom: 24px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-24 {
    margin-bottom: 24px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-25 {
    margin-bottom: 25px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-25 {
    margin-bottom: 25px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-26 {
    margin-bottom: 26px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-26 {
    margin-bottom: 26px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-27 {
    margin-bottom: 27px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-27 {
    margin-bottom: 27px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-28 {
    margin-bottom: 28px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-28 {
    margin-bottom: 28px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-29 {
    margin-bottom: 29px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-29 {
    margin-bottom: 29px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-30 {
    margin-bottom: 30px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-30 {
    margin-bottom: 30px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-31 {
    margin-bottom: 31px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-31 {
    margin-bottom: 31px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-32 {
    margin-bottom: 32px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-32 {
    margin-bottom: 32px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-33 {
    margin-bottom: 33px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-33 {
    margin-bottom: 33px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-34 {
    margin-bottom: 34px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-34 {
    margin-bottom: 34px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-35 {
    margin-bottom: 35px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-35 {
    margin-bottom: 35px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-36 {
    margin-bottom: 36px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-36 {
    margin-bottom: 36px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-37 {
    margin-bottom: 37px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-37 {
    margin-bottom: 37px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-38 {
    margin-bottom: 38px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-38 {
    margin-bottom: 38px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-39 {
    margin-bottom: 39px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-39 {
    margin-bottom: 39px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-40 {
    margin-bottom: 40px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-40 {
    margin-bottom: 40px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-41 {
    margin-bottom: 41px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-41 {
    margin-bottom: 41px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-42 {
    margin-bottom: 42px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-42 {
    margin-bottom: 42px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-43 {
    margin-bottom: 43px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-43 {
    margin-bottom: 43px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-44 {
    margin-bottom: 44px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-44 {
    margin-bottom: 44px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-45 {
    margin-bottom: 45px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-45 {
    margin-bottom: 45px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-46 {
    margin-bottom: 46px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-46 {
    margin-bottom: 46px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-47 {
    margin-bottom: 47px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-47 {
    margin-bottom: 47px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-48 {
    margin-bottom: 48px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-48 {
    margin-bottom: 48px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-49 {
    margin-bottom: 49px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-49 {
    margin-bottom: 49px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-50 {
    margin-bottom: 50px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-50 {
    margin-bottom: 50px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-51 {
    margin-bottom: 51px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-51 {
    margin-bottom: 51px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-52 {
    margin-bottom: 52px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-52 {
    margin-bottom: 52px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-53 {
    margin-bottom: 53px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-53 {
    margin-bottom: 53px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-54 {
    margin-bottom: 54px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-54 {
    margin-bottom: 54px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-55 {
    margin-bottom: 55px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-55 {
    margin-bottom: 55px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-56 {
    margin-bottom: 56px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-56 {
    margin-bottom: 56px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-57 {
    margin-bottom: 57px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-57 {
    margin-bottom: 57px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-58 {
    margin-bottom: 58px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-58 {
    margin-bottom: 58px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-59 {
    margin-bottom: 59px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-59 {
    margin-bottom: 59px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-60 {
    margin-bottom: 60px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-60 {
    margin-bottom: 60px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-61 {
    margin-bottom: 61px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-61 {
    margin-bottom: 61px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-62 {
    margin-bottom: 62px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-62 {
    margin-bottom: 62px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-63 {
    margin-bottom: 63px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-63 {
    margin-bottom: 63px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-64 {
    margin-bottom: 64px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-64 {
    margin-bottom: 64px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-65 {
    margin-bottom: 65px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-65 {
    margin-bottom: 65px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-66 {
    margin-bottom: 66px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-66 {
    margin-bottom: 66px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-67 {
    margin-bottom: 67px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-67 {
    margin-bottom: 67px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-68 {
    margin-bottom: 68px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-68 {
    margin-bottom: 68px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-69 {
    margin-bottom: 69px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-69 {
    margin-bottom: 69px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-70 {
    margin-bottom: 70px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-70 {
    margin-bottom: 70px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-71 {
    margin-bottom: 71px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-71 {
    margin-bottom: 71px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-72 {
    margin-bottom: 72px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-72 {
    margin-bottom: 72px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-73 {
    margin-bottom: 73px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-73 {
    margin-bottom: 73px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-74 {
    margin-bottom: 74px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-74 {
    margin-bottom: 74px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-75 {
    margin-bottom: 75px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-75 {
    margin-bottom: 75px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-76 {
    margin-bottom: 76px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-76 {
    margin-bottom: 76px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-77 {
    margin-bottom: 77px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-77 {
    margin-bottom: 77px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-78 {
    margin-bottom: 78px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-78 {
    margin-bottom: 78px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-79 {
    margin-bottom: 79px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-79 {
    margin-bottom: 79px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-80 {
    margin-bottom: 80px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-80 {
    margin-bottom: 80px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-81 {
    margin-bottom: 81px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-81 {
    margin-bottom: 81px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-82 {
    margin-bottom: 82px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-82 {
    margin-bottom: 82px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-83 {
    margin-bottom: 83px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-83 {
    margin-bottom: 83px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-84 {
    margin-bottom: 84px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-84 {
    margin-bottom: 84px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-85 {
    margin-bottom: 85px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-85 {
    margin-bottom: 85px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-86 {
    margin-bottom: 86px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-86 {
    margin-bottom: 86px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-87 {
    margin-bottom: 87px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-87 {
    margin-bottom: 87px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-88 {
    margin-bottom: 88px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-88 {
    margin-bottom: 88px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-89 {
    margin-bottom: 89px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-89 {
    margin-bottom: 89px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-90 {
    margin-bottom: 90px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-90 {
    margin-bottom: 90px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-91 {
    margin-bottom: 91px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-91 {
    margin-bottom: 91px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-92 {
    margin-bottom: 92px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-92 {
    margin-bottom: 92px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-93 {
    margin-bottom: 93px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-93 {
    margin-bottom: 93px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-94 {
    margin-bottom: 94px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-94 {
    margin-bottom: 94px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-95 {
    margin-bottom: 95px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-95 {
    margin-bottom: 95px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-96 {
    margin-bottom: 96px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-96 {
    margin-bottom: 96px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-97 {
    margin-bottom: 97px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-97 {
    margin-bottom: 97px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-98 {
    margin-bottom: 98px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-98 {
    margin-bottom: 98px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-99 {
    margin-bottom: 99px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-99 {
    margin-bottom: 99px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-100 {
    margin-bottom: 100px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-100 {
    margin-bottom: 100px !important;
  }
}

/* ====================================================
reset style
==================================================== */
html {
  overflow-y: scroll;
  overflow-x: hidden;
  font-size: 10px;
  /* Base font size, change to 14px or 16px if needed */
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,
img {
  border: 0;
}

img {
  vertical-align: middle;
  max-width: 100%;
}

address,
caption,
cite,
code,
dfn,
var {
  font-style: normal;
  font-weight: normal;
}

li {
  list-style: none;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before,
q:after {
  content: "";
}

abbr,
acronym {
  border: 0;
  font-variant: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input,
textarea,
select,
button {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/*to enable resizing for IE*/
input,
textarea,
select {
  *font-size: 100%;
}

/*because legend doesn't inherit in IE */
legend {
  color: #000;
}

del,
ins {
  text-decoration: none;
}

main {
  display: block;
}

section {
  position: relative;
}

/* ====================================================
HTML
==================================================== */
html {
  font-size: 62.5%;
}

body {
  color: #1A1A1A;
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "MS Pゴシック", "MS PGothic", sans-serif;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  background: #fff;
  overflow-x: hidden;
}

a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
  outline: none;
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  a {
    display: inline-block;
  }
}

a:hover {
  cursor: pointer;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* ====================================================
Layout
==================================================== */
.inner {
  width: 90%;
  margin: 0 auto;
  max-width: 1000px;
}

@media screen and (max-width: 834px) {
  .inner {
    width: 100%;
    padding: 0 15px;
  }
}

.wrapper {
  overflow: hidden;
}

/* ====================================================
Control
==================================================== */
.c-fc--ff {
  color: #fff;
}

.c-ft--en {
  font-family: "Montserrat", serif;
}

.c-fw--lt {
  font-weight: 300 !important;
}

.c-fw--rg {
  font-weight: 400 !important;
}

.c-fw--md {
  font-weight: 500 !important;
}

.c-fw--sbd {
  font-weight: 600 !important;
}

.c-fw--bd {
  font-weight: 700 !important;
}

.c-fw--bk {
  font-weight: 900 !important;
}

.c-tx--v {
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
}

@media screen and (min-width: 835px) {
  .c-tx--v-pc {
    -ms-writing-mode: tb-rl;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
  }
}

.c-al--c {
  text-align: center !important;
}

.c-al--r {
  text-align: right !important;
}

.c-al--l {
  text-align: left !important;
}

.c-val--md {
  vertical-align: middle;
}

.c-val--top {
  vertical-align: top;
}

/* ====================================================
Component
==================================================== */
.c-txt__01 {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2.075;
}

.c-ttl__01 {
  margin-bottom: 9px;
}

@media screen and (max-width: 834px) {
  .c-ttl__01 {
    margin-bottom: 24px;
  }
}

.c-ttl__01 .c-ft--en {
  font-size: 3.2rem;
  letter-spacing: 0.4rem;
  margin-right: 15px;
}

@media screen and (max-width: 834px) {
  .c-ttl__01 .c-ft--en {
    font-size: 2.5rem;
    letter-spacing: 0.3rem;
    margin-right: 13px;
  }
}

.c-ttl__01 .c-ft--base {
  font-size: 1rem;
}

.c-ttl__01--border {
  position: relative;
  margin-bottom: 60px;
}

@media screen and (max-width: 834px) {
  .c-ttl__01--border {
    padding-top: 77px;
    text-align: center;
  }

  .c-ttl__01--border>* {
    display: block;
    margin-right: 0;
  }

  .c-ttl__01--border>*:not(:last-child) {
    margin-bottom: 8px;
  }
}

.c-ttl__01--border:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: calc((100vw - 1000px) / 2);
  height: 1px;
  background: #1A1A1A;
  right: calc(100% + 30px);
  top: calc(50% + 3px);
}

@media screen and (max-width: 834px) {
  .c-ttl__01--border:before {
    height: 60px;
    width: 1px;
    top: 0;
    right: 50%;
    transform: translateX(50%);
  }
}

.c-ttl__01--border .c-ft--en {
  margin-right: 16px;
}

@media screen and (max-width: 834px) {
  .c-ttl__01--border .c-ft--en {
    font-size: 2.2rem;
    letter-spacing: 0.2rem;
    margin-right: 0;
  }
}

.c-ttl__01--center {
  text-align: center;
}

.c-ttl__01--center>* {
  display: block;
}

.c-ttl__01--center .c-ft--en {
  margin-right: 0;
}

@media screen and (max-width: 834px) {
  .c-ttl__01--center .c-ft--en {
    font-size: 2.2rem;
  }
}

.c-ttl__01--center .c-ft--en:not(:last-child) {
  margin-bottom: 9px;
}

.c-ttl__02 {
  color: #fff;
}

.c-ttl__02>* {
  display: block;
}

.c-ttl__02 .c-ft--en {
  font-size: 4.2rem;
  letter-spacing: 0.5rem;
  margin-bottom: 5px;
}

@media screen and (max-width: 834px) {
  .c-ttl__02 .c-ft--en {
    font-size: 2.2rem;
    letter-spacing: 0.2rem;
  }
}

.c-ttl__02 .c-ft--base {
  font-size: 1rem;
}

.c-ttl__03 {
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1.625;
}

@media screen and (max-width: 834px) {
  .c-ttl__03 {
    font-size: 20px;
    font-size: 2rem;
    line-height: 1.162;
  }
}

.c-btn__01 {
  font-size: 1.4rem;
  line-height: 1;
  color: #fff;
  letter-spacing: 0.16rem;
  background: #1A1A1A;
  font-family: "Montserrat", serif;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 40px;
  width: 160px;
  padding: 0 15px;
  position: relative;
  transition: 0.3s ease;
}

@media screen and (max-width: 834px) {
  .c-btn__01 {
    font-size: 1rem;
    height: 31px;
    width: 165px;
    letter-spacing: 0.1rem;
  }
}

.c-btn__01:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 9px;
  height: 9px;
  border-left: 1px solid #1A1A1A;
  border-top: 1px solid #1A1A1A;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.3s ease;
}

.c-btn__01:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 9px;
  height: 9px;
  border-left: 1px solid #1A1A1A;
  border-bottom: 1px solid #1A1A1A;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: all 0.3s ease;
}

.c-btn__01 span:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 9px;
  height: 9px;
  border-right: 1px solid #1A1A1A;
  border-top: 1px solid #1A1A1A;
  top: 0;
  right: 0;
  opacity: 0;
  transition: all 0.3s ease;
}

.c-btn__01 span:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 9px;
  height: 9px;
  border-right: 1px solid #1A1A1A;
  border-bottom: 1px solid #1A1A1A;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: all 0.3s ease;
}

.c-btn__01:hover {
  background: transparent;
  color: #1A1A1A;
}

.c-btn__01:hover::before,
.c-btn__01:hover::after {
  opacity: 1;
}

.c-btn__01:hover span::before,
.c-btn__01:hover span::after {
  opacity: 1;
}

.c-btn__01--white {
  background: #fff;
  color: #1A1A1A;
}

.c-btn__01--white:hover {
  background: transparent;
  color: #fff;
}

.c-btn__01--white:hover::before,
.c-btn__01--white:hover::after {
  border-color: #fff;
}

.c-btn__01--white:hover span::before,
.c-btn__01--white:hover span::after {
  border-color: #fff;
}

.c-btn__02 {
  font-size: 2.8rem;
  font-family: "Montserrat", serif;
  letter-spacing: 0.3rem;
  color: #A089C3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 78px;
  background: #fff;
  border: 1px #A089C3 solid;
  margin-bottom: 16px;
  transition: 0.3s ease;
}

@media screen and (max-width: 834px) {
  .c-btn__02 {
    font-size: 2rem;
    letter-spacing: 0.2rem;
    height: 56px;
    max-width: 325px;
    margin: 0 auto 10px;
  }
}

.c-btn__02 small {
  font-size: 2rem;
  margin-top: 6px;
}

@media screen and (max-width: 834px) {
  .c-btn__02 small {
    font-size: 1.4rem;
  }
}

.c-btn__02:hover {
  background: #A089C3;
  color: #fff;
  transition: 0.3s ease;
}

.c-btn__02--purple {
  background: #A089C3;
  color: #fff;
  font-size: 2.4rem;
}

@media screen and (max-width: 834px) {
  .c-btn__02--purple {
    font-size: 1.6rem;
  }
}

.c-btn__02--purple:hover {
  background: #fff;
  color: #A089C3;
}

.c-btn__03 {
  background: #FFD9EF;
  min-height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.75;
  font-family: "Montserrat", serif;
  position: relative;
}

@media screen and (max-width: 834px) {
  .c-btn__03 {
    min-height: 30px;
    font-size: 1rem;
  }
}

.c-btn__03:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 9px;
  height: 9px;
  border-left: 1px solid #1A1A1A;
  border-top: 1px solid #1A1A1A;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.3s ease;
}

.c-btn__03:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 9px;
  height: 9px;
  border-left: 1px solid #1A1A1A;
  border-bottom: 1px solid #1A1A1A;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: all 0.3s ease;
}

.c-btn__03 span:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 9px;
  height: 9px;
  border-right: 1px solid #1A1A1A;
  border-top: 1px solid #1A1A1A;
  top: 0;
  right: 0;
  opacity: 0;
  transition: all 0.3s ease;
}

.c-btn__03 span:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 9px;
  height: 9px;
  border-right: 1px solid #1A1A1A;
  border-bottom: 1px solid #1A1A1A;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: all 0.3s ease;
}

.c-btn__03:hover {
  background: white;
}

.c-btn__03:hover::before,
.c-btn__03:hover::after {
  opacity: 1;
}

.c-btn__03:hover span::before,
.c-btn__03:hover span::after {
  opacity: 1;
}

/* ====================================================
Utility
==================================================== */
@media screen and (max-width: 834px) {
  .pc {
    display: none;
  }
}

@media screen and (min-width: 835px) {
  .sp {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .pc-sm {
    display: none;
  }
}

@media screen and (min-width: 641px) {
  .sp-sm {
    display: none;
  }
}

.u-fn-hover {
  display: inline-block;
}

@media (any-hover: hover) {
  .u-fn-hover {
    transition: all 0.2s;
  }

  .u-fn-hover:hover {
    opacity: 0.7;
  }
}

.u-pull--left {
  margin-left: calc((100vw - 100%) / 2 * -1);
}

.u-pull--right {
  margin-right: calc((100vw - 100%) / 2 * -1);
}

.u-img-of {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  font-family: "object-fit: cover; object-position: center", sans-serif;
}

.c-scroll-box {
  padding-bottom: 20px;
  overflow-x: scroll;
  overflow-y: hidden;
}

.c-scroll-box-y {
  padding-bottom: 20px;
  overflow-x: hidden;
  overflow-y: scroll;
}

@media screen and (max-width: 834px) {
  .c-scroll-box-sp {
    padding-bottom: 20px;
    overflow-x: scroll;
    overflow-y: hidden;
  }
}

.js-u__scrollin {
  opacity: 0;
  transform: translate(0, 70px);
}

.js-u__scrollin.is-visible {
  opacity: 1;
  transform: translate(0, 0);
  transition: 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

/* ====================================================
Header
==================================================== */
.main-header {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(255, 255, 255, 0.01));
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  z-index: 1000;
  transition: 0.3s;
}

@media screen and (max-width: 834px) {
  .main-header {
    height: 70px;
  }
}

.main-header__inner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 37px 131px 6px 60px;
}

@media screen and (max-width: 834px) {
  .main-header__inner {
    padding: 21px 70px 21px 20px;
  }
}

@media screen and (max-width: 640px) {
  .main-header__inner {
    padding: 21px 20px;
  }
}

.main-header__logo {
  width: 200px;
  transition: 0.3s ease;
}

@media screen and (max-width: 834px) {
  .main-header__logo {
    width: 125px;
    margin-bottom: 10px;
  }
}

.main-header__logo:hover {
  opacity: 0.7;
  transition: 0.3s ease;
}

.main-header__navGlobal {
  width: calc(100% - 200px);
}

@media screen and (max-width: 834px) {
  .main-header__navGlobal {
    width: calc(100% - 125px);
  }
}

@media screen and (max-width: 640px) {
  .main-header__navGlobal {
    width: 100%;
  }
}

.main-header__menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (max-width: 834px) {
  .main-header__menu {
    margin-top: -10px;
  }
}

@media screen and (max-width: 640px) {
  .main-header__menu {
    margin-top: 0;
    justify-content: start;
    flex-wrap: wrap;
  }
}

.main-header__menu-item {
  margin-left: 1.6em;
  font-size: 1.8rem;
  background: rgba(0, 0, 0, 0.3);
  border: 1px transparent solid;
  position: relative;
  transition: 0.3s ease;
}

.main-header__menu-item:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 12px;
  height: 12px;
  top: -1px;
  left: -1px;
  border-top: 1px #fff solid;
  border-left: 1px #fff solid;
}

@media screen and (max-width: 834px) {
  .main-header__menu-item:before {
    width: 8px;
    height: 8px;
  }
}

.main-header__menu-item:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 12px;
  height: 12px;
  top: -1px;
  right: -1px;
  border-top: 1px #fff solid;
  border-right: 1px #fff solid;
}

@media screen and (max-width: 834px) {
  .main-header__menu-item:after {
    width: 8px;
    height: 8px;
  }
}

@media screen and (max-width: 990px) {
  .main-header__menu-item {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 834px) {
  .main-header__menu-item {
    font-size: 1.2rem;
    border: none;
    background: rgba(0, 0, 0, 0.5);
    margin: 0 15px 10px 0;
  }

  .main-header__menu-item:nth-child(2) {
    margin-right: 0;
  }
}

.main-header__menu-item a {
  color: #fff;
  font-family: "Montserrat", serif;
  letter-spacing: 0.2rem;
  padding: 13px 35px;
  position: relative;
}

@media screen and (max-width: 990px) {
  .main-header__menu-item a {
    padding: 13px 25px;
  }
}

@media screen and (max-width: 834px) {
  .main-header__menu-item a {
    padding: 5px 12px;
    letter-spacing: 0.2rem;
    min-width: 158px;
    text-align: center;
  }
}

@media screen and (max-width: 374px) {
  .main-header__menu-item a {
    min-width: inherit;
  }
}

.main-header__menu-item a:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 12px;
  height: 12px;
  bottom: -1px;
  left: -1px;
  border-bottom: 1px #fff solid;
  border-left: 1px #fff solid;
}

@media screen and (max-width: 834px) {
  .main-header__menu-item a:before {
    width: 8px;
    height: 8px;
  }
}

.main-header__menu-item a:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 12px;
  height: 12px;
  bottom: -1px;
  right: -1px;
  border-bottom: 1px #fff solid;
  border-right: 1px #fff solid;
}

@media screen and (max-width: 834px) {
  .main-header__menu-item a:after {
    width: 8px;
    height: 8px;
  }
}

.main-header__menu-item a span {
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "MS Pゴシック", "MS PGothic", sans-serif;
}

.main-header__menu-item:hover {
  background: rgba(0, 0, 0, 0.5);
  border: 1px #fff solid;
  transition: 0.3s ease;
}

@media screen and (min-width: 1201px) {
  .main-header__menu-item:first-child a {
    padding: 13px 25px;
  }
}

.main-header__link {
  display: inline-block;
  font-weight: 400;
}

.main-header__box {
  display: none;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #4D4D4D;
}

.main-header__box-inner {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  max-width: 1090px;
  padding: 0 15px;
  margin: 0 auto;
}

@media screen and (max-width: 834px) {
  .main-header__box-inner {
    display: block;
    text-align: center;
    overflow-y: auto;
    padding: 89px 15px 50px;
  }
}

.main-header__box-menu {
  width: 30%;
}

@media screen and (max-width: 834px) {
  .main-header__box-menu {
    width: 100%;
    margin-bottom: 20px;
  }
}

.main-header__box-menu-item {
  margin-bottom: 50px;
}

@media screen and (max-width: 990px) {
  .main-header__box-menu-item {
    margin-bottom: 20px;
  }
}

.main-header__box-menu-item:last-child {
  margin-bottom: 0;
}

.main-header__box-menu-item a {
  font-size: 2.4rem;
  letter-spacing: 0.3rem;
  color: #fff;
  font-family: "Montserrat", serif;
  transition: 0.3s ease;
}

@media screen and (max-width: 990px) {
  .main-header__box-menu-item a {
    font-size: 2rem;
  }
}

@media screen and (max-width: 834px) {
  .main-header__box-menu-item a {
    font-size: 1.6rem;
    letter-spacing: 0.2rem;
  }
}

.main-header__box-menu-item a:hover {
  opacity: 0.7;
  transition: 0.3s ease;
}

.main-header__box-brand {
  width: 40%;
  padding-left: 7px;
}

@media screen and (max-width: 834px) {
  .main-header__box-brand {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    border-top: 1px #fff solid;
    padding: 39px 14.5%;
    margin-top: 19px;
  }
}

.main-header__box-brand-item {
  border-bottom: 1px #fff solid;
  padding-bottom: 50px;
  margin-bottom: 50px;
}

@media screen and (max-width: 990px) {
  .main-header__box-brand-item {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 834px) {
  .main-header__box-brand-item {
    border: none;
    padding-bottom: 0;
    margin-bottom: 20px;
  }
}

.main-header__box-brand-item:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.main-header__box-brand-item a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  font-size: 1.8rem;
  color: #fff;
  transition: 0.3s ease;
}

@media screen and (max-width: 834px) {
  .main-header__box-brand-item a {
    font-size: 1.2rem;
  }
}

.main-header__box-brand-item a:hover .main-header__box-brand-ttl {
  border: 1px #fff solid;
  transition: 0.3s ease;
}

.main-header__box-brand-img {
  width: 130px;
  text-align: center;
}

@media screen and (max-width: 834px) {
  .main-header__box-brand-img {
    width: 68px;
  }
}

.main-header__box-brand-ttl {
  width: calc(100% - 170px);
  background: rgba(0, 0, 0, 0.25);
  border: 1px transparent solid;
  text-align: center;
  position: relative;
  font-family: "Montserrat", serif;
  letter-spacing: 0.2rem;
  transition: 0.3s ease;
}

@media screen and (max-width: 834px) {
  .main-header__box-brand-ttl {
    width: calc(100% - 86px);
    letter-spacing: 0;
  }
}

.main-header__box-brand-ttl:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 12px;
  height: 12px;
  top: -1px;
  left: -1px;
  border-top: 1px #fff solid;
  border-left: 1px #fff solid;
}

@media screen and (max-width: 834px) {
  .main-header__box-brand-ttl:before {
    width: 8px;
    height: 8px;
  }
}

.main-header__box-brand-ttl:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 12px;
  height: 12px;
  top: -1px;
  right: -1px;
  border-top: 1px #fff solid;
  border-right: 1px #fff solid;
}

@media screen and (max-width: 834px) {
  .main-header__box-brand-ttl:after {
    width: 8px;
    height: 8px;
  }
}

.main-header__box-brand-ttl span {
  display: block;
  padding: 17px 5px;
  position: relative;
}

@media screen and (max-width: 834px) {
  .main-header__box-brand-ttl span {
    padding: 8px 5px;
  }
}

.main-header__box-brand-ttl span:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 12px;
  height: 12px;
  bottom: -1px;
  left: -1px;
  border-left: 1px #fff solid;
  border-bottom: 1px #ffffff solid;
}

@media screen and (max-width: 834px) {
  .main-header__box-brand-ttl span:before {
    width: 8px;
    height: 8px;
  }
}

.main-header__box-brand-ttl span:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 12px;
  height: 12px;
  bottom: -1px;
  right: -1px;
  border-right: 1px #fff solid;
  border-bottom: 1px #fff solid;
}

@media screen and (max-width: 834px) {
  .main-header__box-brand-ttl span:after {
    width: 8px;
    height: 8px;
  }
}

.main-header__box.is-show {
  background: #4D4D4D;
  height: 100vh;
}

.main-header__mobile-icon {
  position: absolute;
  top: 30px;
  right: 45px;
  width: 70px;
  height: 70px;
  z-index: 102;
  transition: 0.3s ease;
  cursor: pointer;
}

@media screen and (max-width: 834px) {
  .main-header__mobile-icon {
    top: 1px;
    right: 3px;
    width: 60px;
    height: 60px;
  }
}

.main-header__mobile-icon-border {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 40px;
  height: 1px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.3s;
  background: #fff;
}

@media screen and (max-width: 834px) {
  .main-header__mobile-icon-border {
    width: 25px;
  }
}

.main-header__mobile-icon-border:nth-child(1) {
  width: 25px;
  margin-top: 16px;
  margin-left: 7px;
}

@media screen and (max-width: 834px) {
  .main-header__mobile-icon-border:nth-child(1) {
    width: 15px;
    margin-top: 10px;
    margin-left: 4px;
  }
}

.main-header__mobile-icon-border:nth-child(3) {
  margin-top: -16px;
}

@media screen and (max-width: 834px) {
  .main-header__mobile-icon-border:nth-child(3) {
    margin-top: -10px;
  }
}

.main-header__mobile-icon.mobile-close {
  right: 48px;
}

@media screen and (max-width: 834px) {
  .main-header__mobile-icon.mobile-close {
    right: -3px;
    top: 6px;
  }
}

.main-header__mobile-icon.mobile-close .main-header__mobile-icon-border:nth-child(1) {
  display: none;
}

.main-header__mobile-icon.mobile-close .main-header__mobile-icon-border:nth-child(2) {
  width: 48px;
  transform: rotate(-32deg) translateX(30%);
  left: 0;
}

@media screen and (max-width: 834px) {
  .main-header__mobile-icon.mobile-close .main-header__mobile-icon-border:nth-child(2) {
    width: 28px;
    transform: rotate(-32deg) translateX(36%);
  }
}

.main-header__mobile-icon.mobile-close .main-header__mobile-icon-border:nth-child(3) {
  width: 48px;
  transform: rotate(32deg) translateX(30%);
  left: 0;
}

@media screen and (max-width: 834px) {
  .main-header__mobile-icon.mobile-close .main-header__mobile-icon-border:nth-child(3) {
    width: 28px;
    transform: rotate(32deg) translateX(36%);
  }
}

.main-header__mobile-icon:hover {
  opacity: 0.7;
  transition: 0.3s ease;
}

@media screen and (max-width: 1024px) {
  @-webkit-keyframes click-header-sp-bar-1-close {
    0% {
      transform: translateY(5px) rotate(45deg);
    }

    50% {
      transform: translateY(5px) rotate(0);
    }

    100% {
      transform: translateY(0) rotate(0);
    }
  }

  @keyframes click-header-sp-bar-1-close {
    0% {
      transform: translateY(5px) rotate(45deg);
    }

    50% {
      transform: translateY(5px) rotate(0);
    }

    100% {
      transform: translateY(0) rotate(0);
    }
  }

  @-webkit-keyframes click-header-sp-bar-2-close {
    0% {
      opacity: 0;
    }

    20% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes click-header-sp-bar-2-close {
    0% {
      opacity: 0;
    }

    20% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes click-header-sp-bar-3-close {
    0% {
      transform: translateY(-5px) rotate(-45deg);
    }

    50% {
      transform: translateY(-5px) rotate(0);
    }

    100% {
      transform: translateY(0) rotate(0);
    }
  }

  @keyframes click-header-sp-bar-3-close {
    0% {
      transform: translateY(-5px) rotate(-45deg);
    }

    50% {
      transform: translateY(-5px) rotate(0);
    }

    100% {
      transform: translateY(0) rotate(0);
    }
  }

  .mobile-close .main-header__mobile-icon-border:nth-child(1) {
    margin-top: -5px;
    -webkit-animation: click-header-sp-bar-1-open 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
    animation: click-header-sp-bar-1-open 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
  }

  @-webkit-keyframes click-header-sp-bar-1-open {
    0% {
      transform: translateY(0) rotate(0);
    }

    50% {
      transform: translateY(5px) rotate(0);
    }

    100% {
      transform: translateY(5px) rotate(45deg);
    }
  }

  @keyframes click-header-sp-bar-1-open {
    0% {
      transform: translateY(0) rotate(0);
    }

    50% {
      transform: translateY(5px) rotate(0);
    }

    100% {
      transform: translateY(5px) rotate(45deg);
    }
  }

  @-webkit-keyframes click-header-sp-bar-2-open {
    0% {
      opacity: 1;
    }

    80% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes click-header-sp-bar-2-open {
    0% {
      opacity: 1;
    }

    80% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @-webkit-keyframes click-header-sp-bar-3-open {
    0% {
      transform: translateY(0) rotate(0);
    }

    50% {
      transform: translateY(-5px) rotate(0);
    }

    100% {
      transform: translateY(-5px) rotate(-45deg);
    }
  }

  @keyframes click-header-sp-bar-3-open {
    0% {
      transform: translateY(0) rotate(0);
    }

    50% {
      transform: translateY(-5px) rotate(0);
    }

    100% {
      transform: translateY(-5px) rotate(-45deg);
    }
  }
}

.js-locked {
  overflow: hidden;
  touch-action: none;
}

.js-locked body {
  -webkit-overflow-scrolling: auto;
}

/* ====================================================
Footer
==================================================== */
.main-footer {
  position: relative;
  padding: 119px 0 40px;
  color: #fff;
  background: #4D4D4D;
}

@media screen and (max-width: 834px) {
  .main-footer {
    padding: 59px 0 30px;
  }
}

.main-footer .inner {
  max-width: 1140px;
}

.main-footer__content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 41px;
}

@media screen and (max-width: 834px) {
  .main-footer__content {
    margin-bottom: 29px;
  }
}

.main-footer__clmL {
  width: 65%;
  padding-right: 4.3%;
}

@media screen and (max-width: 834px) {
  .main-footer__clmL {
    width: 100%;
    padding-right: 0;
  }
}

.main-footer__clmR {
  width: 35%;
  max-width: 319px;
}

@media screen and (max-width: 834px) {
  .main-footer__clmR {
    width: 100%;
    max-width: inherit;
  }
}

@media screen and (max-width: 640px) {
  .main-footer__clmR {
    padding: 0 15%;
    margin-bottom: 39px;
  }
}

@media screen and (max-width: 374px) {
  .main-footer__clmR {
    padding: 0 5%;
  }
}

@media screen and (max-width: 834px) {
  .main-footer__box {
    margin-bottom: 39px;
  }
}

.main-footer__box-item {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px #fff solid;
  padding-bottom: 31px;
  margin-bottom: 29px;
}

@media screen and (max-width: 834px) {
  .main-footer__box-item {
    justify-content: space-between;
    padding-bottom: 19px;
    margin-bottom: 19px;
  }
}

.main-footer__box-item:last-child {
  border-bottom: none;
  margin-bottom: 25px;
}

.main-footer__box-link {
  width: calc(100% - 215px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-right: 60px;
  transition: 0.3s ease;
}

@media screen and (max-width: 1200px) {
  .main-footer__box-link {
    padding-right: 10px;
  }
}

@media screen and (max-width: 834px) {
  .main-footer__box-link {
    max-width: 300px;
  }
}

@media screen and (max-width: 640px) {
  .main-footer__box-link {
    max-width: 100%;
    width: 100%;
    padding: 0 16% 0 13%;
  }
}

@media screen and (max-width: 374px) {
  .main-footer__box-link {
    padding: 0 5%;
  }
}

.main-footer__box-link-img {
  width: 130px;
  text-align: center;
}

@media screen and (max-width: 990px) {
  .main-footer__box-link-img {
    width: 90px;
  }
}

@media screen and (max-width: 640px) {
  .main-footer__box-link-img {
    width: 70px;
  }
}

.main-footer__box-link-ttl {
  width: calc(100% - 171px);
  font-size: 1.8rem;
  border: 1px transparent solid;
  font-family: "Montserrat", serif;
  letter-spacing: 0.2rem;
  background: rgba(0, 0, 0, 0.3);
  text-align: center;
  position: relative;
  transition: 0.3s ease;
}

@media screen and (max-width: 990px) {
  .main-footer__box-link-ttl {
    width: calc(100% - 110px);
  }
}

@media screen and (max-width: 834px) {
  .main-footer__box-link-ttl {
    font-size: 1.2rem;
    background: rgba(0, 0, 0, 0.5);
  }
}

@media screen and (max-width: 640px) {
  .main-footer__box-link-ttl {
    width: calc(100% - 88px);
    border: none;
  }
}

.main-footer__box-link-ttl:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 12px;
  height: 12px;
  top: -1px;
  left: -1px;
  border-top: 1px #fff solid;
  border-left: 1px #fff solid;
}

@media screen and (max-width: 834px) {
  .main-footer__box-link-ttl:before {
    width: 8px;
    height: 8px;
  }
}

.main-footer__box-link-ttl:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 12px;
  height: 12px;
  top: -1px;
  right: -1px;
  border-top: 1px #fff solid;
  border-right: 1px #fff solid;
}

@media screen and (max-width: 834px) {
  .main-footer__box-link-ttl:after {
    width: 8px;
    height: 8px;
  }
}

.main-footer__box-link-ttl span {
  display: block;
  position: relative;
  padding: 16px 5px;
}

@media screen and (max-width: 834px) {
  .main-footer__box-link-ttl span {
    padding: 8px 5px;
  }
}

.main-footer__box-link-ttl span:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 12px;
  height: 12px;
  bottom: -1px;
  left: -1px;
  border-bottom: 1px #fff solid;
  border-left: 1px #fff solid;
}

@media screen and (max-width: 834px) {
  .main-footer__box-link-ttl span:before {
    width: 8px;
    height: 8px;
  }
}

.main-footer__box-link-ttl span:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 12px;
  height: 12px;
  bottom: -1px;
  right: -1px;
  border-bottom: 1px #fff solid;
  border-right: 1px #fff solid;
}

@media screen and (max-width: 834px) {
  .main-footer__box-link-ttl span:after {
    width: 8px;
    height: 8px;
  }
}

.main-footer__box-link:hover .main-footer__box-link-ttl {
  border: 1px #fff solid;
  background: rgba(0, 0, 0, 0.5);
  transition: 0.3s ease;
}

.main-footer__box-list {
  width: 215px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 640px) {
  .main-footer__box-list {
    display: none;
    width: 180px;
  }
}

.main-footer__box-list-item {
  width: 33.33%;
  padding-right: 5px;
}

@media screen and (max-width: 640px) {
  .main-footer__box-list-item {
    padding-right: 0;
  }
}

.main-footer__box-list-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 50px;
  height: 50px;
  padding: 10px;
  border: 1px #fff solid;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.25);
  transition: 0.3s ease;
  margin-left: auto;
}

@media screen and (max-width: 640px) {
  .main-footer__box-list-item a {
    width: 40px;
    height: 40px;
    margin: 0 auto;
  }
}

.main-footer__box-list-item a:hover {
  opacity: 0.7;
  transition: 0.3s ease;
}

.main-footer__box-list--sp {
  display: none;
}

@media screen and (max-width: 640px) {
  .main-footer__box-list--sp {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
  }
}

.main-footer__nav {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.main-footer__menu {
  font-size: 1.8rem;
  font-family: "Montserrat", serif;
  letter-spacing: 0.2rem;
}

@media screen and (max-width: 834px) {
  .main-footer__menu {
    font-size: 1.6rem;
    letter-spacing: 0.16rem;
  }
}

.main-footer__menu:first-child {
  width: calc(62% - 10px);
}

.main-footer__menu:last-child {
  width: calc(38% - 10px);
}

.main-footer__menu-item {
  margin-bottom: 30px;
}

@media screen and (max-width: 834px) {
  .main-footer__menu-item {
    margin-bottom: 21px;
  }
}

.main-footer__menu-item a {
  transition: 0.3s ease;
}

.main-footer__menu-item a:hover {
  opacity: 0.7;
  transition: 0.3s ease;
}

.main-footer__copyright {
  font-family: "Montserrat", serif;
  font-size: 1rem;
  text-align: center;
  letter-spacing: 0.13rem;
}

/* ====================================================
PAGE INDEX
==================================================== */
.top-mv__img img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  min-height: 639px;
}

@media screen and (max-width: 374px) {
  .top-mv__img img {
    min-height: 560px;
  }
}

.top-about {
  padding: 148px 0 144px;
  text-align: center;
}

@media screen and (max-width: 834px) {
  .top-about {
    padding: 79px 0 60px;
  }
}

.top-about__ttl {
  position: relative;
  padding-bottom: 42px;
  margin-bottom: 38px;
}

@media screen and (max-width: 834px) {
  .top-about__ttl {
    margin-bottom: 34px;
  }
}

.top-about__ttl:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 26px;
  height: 1px;
  background: #1A1A1A;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0;
}

.top-about__ttl>* {
  display: block;
}

.top-about__ttl small {
  font-size: 1.6rem;
  color: #B3B3B3;
  letter-spacing: 0.2rem;
  margin-bottom: 30px;
}

@media screen and (max-width: 834px) {
  .top-about__ttl small {
    font-size: 1.4rem;
  }
}

.top-about__ttl span.c-ft--base {
  font-size: 34px;
  font-size: 3.4rem;
  line-height: 1.7882352941;
  letter-spacing: 0.4rem;
  margin-bottom: 28px;
}

@media screen and (max-width: 834px) {
  .top-about__ttl span.c-ft--base {
    font-size: 24px;
    font-size: 2.4rem;
    line-height: 1.75;
    letter-spacing: 0.5rem;
    margin-bottom: 33px;
  }
}

@media screen and (max-width: 374px) {
  .top-about__ttl span.c-ft--base {
    font-size: 2rem;
  }
}

.top-about__ttl span.c-ft--en {
  font-size: 2rem;
  letter-spacing: 0.4rem;
  color: #A089C3;
}

@media screen and (max-width: 834px) {
  .top-about__ttl span.c-ft--en {
    font-size: 1.3rem;
    letter-spacing: 0.3rem;
  }
}

.top-about__lead {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.2;
  letter-spacing: 0.1rem;
  margin-bottom: 18px;
}

@media screen and (max-width: 834px) {
  .top-about__lead {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.875;
    margin-bottom: 11px;
  }
}

.top-about__txt {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.5;
  letter-spacing: 0.08rem;
  color: #ccc;
}

@media screen and (max-width: 834px) {
  .top-about__txt {
    font-size: 10px;
    font-size: 1rem;
    line-height: 1.75;
    letter-spacing: 0.1rem;
  }
}

.top-news {
  padding: 100px 0 145px;
}

@media screen and (max-width: 834px) {
  .top-news {
    padding: 18px 0 79px;
  }
}

.top-news__heading {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: 834px) {
  .top-news__heading-ttl {
    width: 100%;
  }
}

.top-news__heading-btn {
  margin-top: 5px;
}

@media screen and (max-width: 834px) {
  .top-news__heading-btn {
    display: none;
  }
}

.top-news .inner {
  position: relative;
}

.top-news .inner:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: calc(100% - 100px);
  height: 70%;
  top: -17%;
  left: 100px;
  background: #F9F3FF;
  z-index: -1;
}

@media screen and (max-width: 834px) {
  .top-news .inner:before {
    top: 0;
    left: 65px;
    width: calc(100% + 15px);
    height: 420px;
  }
}

.top-news .inner:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: calc((100vw - 1000px) / 2);
  height: 70%;
  top: -17%;
  left: 100%;
  background: #F9F3FF;
}

@media screen and (max-width: 1120px) {
  .top-news .inner:after {
    width: 6%;
  }
}

.top-news__btn {
  display: none;
}

@media screen and (max-width: 834px) {
  .top-news__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
}

.news-list {
  display: grid;
  gap: 33px 33px;
  grid-template-columns: repeat(3, 1fr);
}

@media screen and (max-width: 834px) {
  .news-list {
    margin-bottom: 57px;
  }
}

@media screen and (max-width: 640px) {
  .news-list {
    grid-template-columns: repeat(1, 1fr);
  }
}

.news-list__img {
  margin-bottom: 22px;
  position: relative;
  overflow: hidden;
}

.news-list__img:before {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.news-list__img-thumb {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.news-list__img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  font-family: "object-fit: cover";
}

.news-list__meta {
  font-family: "Montserrat", serif;
  font-size: 1.4rem;
  color: #A089C3;
  margin-bottom: 10px;
}

@media screen and (max-width: 834px) {
  .news-list__meta {
    margin-bottom: 8px;
  }
}

.news-list p {
  line-height: 1.6;
}

@media screen and (max-width: 834px) {
  .news-list p {
    line-height: 1.75;
    letter-spacing: 0.1rem;
  }
}

.top-care {
  padding-bottom: 119px;
  margin-bottom: 149px;
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 834px) {
  .top-care {
    padding-bottom: 90px;
    margin-bottom: 80px;
  }
}

.top-care:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 252px;
  background: url(../img/index/bg-care.jpg) no-repeat center/cover;
  bottom: 0;
  left: 0;
  z-index: -1;
}

@media screen and (max-width: 1535px) {
  .top-care:before {
    height: 55%;
  }
}

@media screen and (max-width: 834px) {
  .top-care:before {
    height: 69%;
  }
}

.care-block {
  display: flex;
  flex-wrap: wrap;
}

.care-block__img {
  width: 52.8%;
}

@media screen and (max-width: 834px) {
  .care-block__img {
    width: 100%;
  }
}

.care-block__img-thumb {
  display: flex;
  margin-left: calc((1000px - 100vw) / 2);
  margin-right: -158px;
}

@media screen and (max-width: 1120px) {
  .care-block__img-thumb {
    margin-left: -11%;
  }
}

@media screen and (max-width: 834px) {
  .care-block__img-thumb {
    margin-right: 25px;
    margin-left: -15px;
  }
}

.care-block__img-thumb img {
  width: 100%;
}

.care-block__ct {
  width: 47.2%;
  padding: 10.4% 0 12% 12.3%;
  background: #fff;
  z-index: 2;
  margin-top: 9.5%;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .care-block__ct {
    padding: 5% 0 5% 5%;
  }
}

@media screen and (max-width: 834px) {
  .care-block__ct {
    width: 100%;
    text-align: justify;
    padding: 47px 39px 59px 40px;
    margin: -76px -15px 0 20px;
  }
}

.care-block__ct:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: calc((100vw - 1000px) / 2);
  height: 100%;
  background: #fff;
  top: 0;
  left: 100%;
}

@media screen and (max-width: 1120px) {
  .care-block__ct:before {
    width: 12%;
  }
}

.care-block p {
  margin-bottom: 33px;
}

@media screen and (max-width: 834px) {
  .care-block p {
    line-height: 1.75;
    letter-spacing: 0.1rem;
    margin-bottom: 55px;
  }
}

@media screen and (max-width: 834px) {
  .care-block__btn {
    margin-left: auto;
  }
}

.top-hair {
  overflow: hidden;
  padding-top: 117px;
  margin-bottom: 147px;
  position: relative;
}

@media screen and (max-width: 834px) {
  .top-hair {
    padding-top: 0;
    margin-bottom: 80px;
  }
}

.top-hair:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 250px;
  background: url(../img/index/bg-gallery.jpg) no-repeat center/cover;
  top: 0;
  right: 0;
  left: calc((100vw - 1000px) / 2 + 267px);
  z-index: -2;
}

@media screen and (max-width: 834px) {
  .top-hair:before {
    left: 30%;
    width: calc(100% + 15px);
  }
}

.top-hair__btn {
  font-size: 1.4rem;
  font-family: "Montserrat", serif;
  margin-left: 85px;
  letter-spacing: 0.18rem;
  transition: 0.3s ease;
}

@media screen and (max-width: 834px) {
  .top-hair__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
}

.top-hair__btn:hover {
  opacity: 0.7;
  transition: 0.3s ease;
}

.top-hair .inner {
  position: relative;
}

.top-hair .inner:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: calc(100% - 100px);
  height: 70%;
  top: 20px;
  right: 100px;
  background: rgba(249, 243, 255, 0.5);
  z-index: -1;
}

@media screen and (max-width: 834px) {
  .top-hair .inner:before {
    width: calc(100% + 30px);
    right: -15px;
    top: 90px;
  }
}

.top-hair .inner:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: calc((100vw - 1000px) / 2);
  height: 70%;
  top: 20px;
  right: 100%;
  background: rgba(249, 243, 255, 0.5);
}

@media screen and (max-width: 1120px) {
  .top-hair .inner:after {
    width: 6%;
  }
}

@media screen and (max-width: 834px) {
  .top-hair .inner:after {
    display: none;
  }
}

.hair-block {
  margin-right: -120px;
  padding-bottom: 55px;
}

@media screen and (max-width: 1024px) {
  .hair-block {
    margin-right: -200px;
  }
}

@media screen and (max-width: 640px) {
  .hair-block {
    margin-right: -100px;
  }
}

.hair-block__item {
  position: relative;
  overflow: hidden;
  margin-right: 40px;
}

@media screen and (max-width: 1024px) {
  .hair-block__item {
    margin-right: 20px;
  }
}

.hair-block__item:before {
  content: "";
  display: block;
  padding-bottom: 153.5947712418%;
}

.hair-block__item-img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  a {
    width: 100%;
    height: 100%;
  }
}

.hair-block__item img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  font-family: "object-fit: cover";
}

.hair-block>.slick-list {
  margin-right: calc((1000px - 100vw) / 2);
}

@media screen and (max-width: 1120px) {
  .hair-block>.slick-list {
    margin-right: 6%;
  }
}

@media screen and (max-width: 640px) {
  .hair-block>.slick-list {
    margin-right: 0;
  }
}

.hair-block .slick-arrow.slick-prev {
  top: auto;
  bottom: -25px;
  left: 0;
  width: 56px;
}

.hair-block .slick-arrow.slick-prev:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 56px;
  height: 7px;
  background: url(../img/index/icon-arr.png) no-repeat center/cover;
  left: 0;
}

@media screen and (max-width: 834px) {
  .hair-block .slick-arrow.slick-prev:before {
    width: 40px;
    height: 5px;
  }
}

@media screen and (max-width: 834px) {
  .hair-block .slick-arrow.slick-prev {
    left: calc(50% - 260px);
  }
}

@media screen and (max-width: 640px) {
  .hair-block .slick-arrow.slick-prev {
    left: calc(50% - 160px);
  }
}

.hair-block .slick-arrow.slick-next {
  top: auto;
  bottom: -25px;
  left: 215px;
  width: 56px;
}

.hair-block .slick-arrow.slick-next:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 56px;
  height: 7px;
  background: url(../img/index/icon-arr-black.png) no-repeat center/cover;
  left: 0;
}

@media screen and (max-width: 834px) {
  .hair-block .slick-arrow.slick-next:before {
    width: 40px;
    height: 5px;
  }
}

@media screen and (max-width: 834px) {
  .hair-block .slick-arrow.slick-next {
    left: calc(50% + 10px);
  }
}

@media screen and (max-width: 640px) {
  .hair-block .slick-arrow.slick-next {
    left: calc(50% + 15px);
  }
}

.top-staff {
  margin-bottom: 145px;
}

@media screen and (max-width: 834px) {
  .top-staff {
    margin-bottom: 75px;
  }
}

.staff-mv {
  position: relative;
}

.staff-mv__ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  width: 100%;
  max-width: 1030px;
  padding: 40px 15px 30px;
  margin: 0 auto;
  color: #fff;
}

@media screen and (max-width: 834px) {
  .staff-mv__ct {
    padding-top: 30px;
  }
}

.staff-mv__img img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  min-height: 420px;
}

@media screen and (max-width: 640px) {
  .staff-mv__img img {
    min-height: 465px;
  }
}

.staff-mv__ttl {
  margin-bottom: 15px;
  position: relative;
}

@media screen and (max-width: 834px) {
  .staff-mv__ttl {
    margin-bottom: 44px;
  }
}

.staff-mv__ttl:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 1px;
  height: 150px;
  background: #fff;
  top: -157px;
  left: 3px;
}

@media screen and (max-width: 834px) {
  .staff-mv__ttl:before {
    display: none;
  }
}

.staff-mv__ttl .c-ft--en {
  font-size: 4.6rem;
  letter-spacing: 0.55rem;
  margin-right: 16px;
}

@media screen and (max-width: 834px) {
  .staff-mv__ttl .c-ft--en {
    font-size: 22px;
    font-size: 2.2rem;
    line-height: 1;
    letter-spacing: 0.25rem;
    display: block;
  }
}

.staff-mv__ttl .c-ft--base {
  font-size: 1rem;
}

.staff-mv p {
  letter-spacing: 0.15rem;
}

@media screen and (max-width: 640px) {
  .staff-mv p {
    line-height: 1.9;
    letter-spacing: 0.06rem;
    margin-bottom: 55px;
  }
}

.staff-mv__btn {
  position: absolute;
  bottom: 38px;
  right: 15px;
}

@media screen and (max-width: 640px) {
  .staff-mv__btn {
    position: static;
  }
}

.staff-block {
  display: flex;
  flex-wrap: wrap;
}

.staff-block__item {
  width: 50%;
}

@media screen and (max-width: 640px) {
  .staff-block__item {
    width: 100%;
  }
}

.staff-block__link {
  position: relative;
}

.staff-block__link:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(68, 68, 68, 0.4);
  top: 0;
  left: 0;
  transition: 0.3s ease;
}

.staff-block__link:hover::before {
  background: #444444;
  transition: 0.3s ease;
}

.staff-block__ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  top: 53%;
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 834px) {
  .staff-block__ct {
    top: 49%;
  }
}

.staff-block__img img {
  width: 100%;
}

.top-shop {
  padding-bottom: 133px;
  position: relative;
}

@media screen and (max-width: 834px) {
  .top-shop {
    padding-bottom: 19px;
  }
}

.top-shop:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 85%;
  background: url(../img/index/bg-shop.jpg) no-repeat center/cover;
  bottom: 0;
  left: 0;
  z-index: -1;
}

@media screen and (max-width: 640px) {
  .top-shop:before {
    height: 83%;
  }
}

@media screen and (max-width: 834px) {
  .top-shop__ttl .c-ft--en {
    font-size: 2.2rem;
    letter-spacing: 0.2rem;
  }
}

.shop-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
}

.shop-list__item {
  width: calc(50% - 33px);
}

@media screen and (max-width: 834px) {
  .shop-list__item {
    width: calc(50% - 15px);
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 640px) {
  .shop-list__item {
    width: 100%;
  }
}

.shop-list__img {
  margin-bottom: 40px;
}

@media screen and (max-width: 834px) {
  .shop-list__img {
    margin-bottom: 24px;
  }
}

.shop-list__img img {
  width: 100%;
}

.shop-list__ct-logo {
  margin-bottom: 11px;
}

@media screen and (max-width: 834px) {
  .shop-list__ct-logo {
    margin-bottom: 13px;
  }
}

.shop-list p {
  min-height: 67px;
  margin-bottom: 2px;
}

@media screen and (max-width: 834px) {
  .shop-list p {
    min-height: 86px;
    line-height: 1.75;
    letter-spacing: 0.2rem;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 640px) {
  .shop-list p {
    min-height: inherit;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 374px) {
  .shop-list p {
    font-size: 1.4rem;
  }
}

.page-mv img {
  width: 100%;
}

.sec-recruit {
  padding: 101px 0 131px;
  position: relative;
  z-index: 2;
  background: url(../img/recruit/bg-benefit.jpg) no-repeat center bottom;
  background-size: 100% 46.5%;
}

@media screen and (max-width: 834px) {
  .sec-recruit {
    padding: 30px 0 58px;
    background: url(../img/recruit/bg-benefit-sp.jpg) no-repeat center bottom;
    background-size: 100% 42.6%;
  }
}

.sec-recruit:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 465px;
  height: 579px;
  background: url(../img/recruit/bg-before.png) no-repeat center/contain;
  right: 8.4%;
  top: 24.6%;
  z-index: -1;
}

@media screen and (max-width: 834px) {
  .sec-recruit:before {
    right: -31px;
    top: 28.2%;
    width: 211px;
    height: 332px;
  }
}

.recruit-top__anchor {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px 50px;
  margin-bottom: 139px;
}

@media screen and (max-width: 834px) {
  .recruit-top__anchor {
    gap: 10px 14.8px;
    margin-bottom: 73px;
  }
}

.recruit-top__anchor-item {
  width: calc((100% - 100px) / 3);
}

@media screen and (max-width: 834px) {
  .recruit-top__anchor-item {
    width: calc((100% - 14.8px) / 2);
  }
}

.recruit-top__heading {
  text-align: center;
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.98;
  letter-spacing: 0.15rem;
  position: relative;
  padding-bottom: 131px;
}

@media screen and (max-width: 834px) {
  .recruit-top__heading {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.875;
    margin-bottom: 0;
    padding-bottom: 67px;
  }
}

.recruit-top__heading:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 1px;
  height: 80px;
  background: #1A1A1A;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
}

@media screen and (max-width: 834px) {
  .recruit-top__heading:after {
    height: 30px;
  }
}

.recruit-top__heading-ttl {
  font-size: 34px;
  font-size: 3.4rem;
  line-height: 1.7647058824;
  letter-spacing: 0.26rem;
  margin-bottom: 22px;
}

@media screen and (max-width: 834px) {
  .recruit-top__heading-ttl {
    font-size: 24px;
    font-size: 2.4rem;
    line-height: 1.75;
    letter-spacing: 0.16rem;
    margin-bottom: 18px;
  }
}

@media screen and (max-width: 374px) {
  .recruit-top__heading-ttl {
    font-size: 2.3rem;
  }
}

.recruit-top__heading p:not(:last-child) {
  margin-bottom: 24px;
}

@media screen and (max-width: 834px) {
  .recruit-top__heading p:not(:last-child) {
    margin-bottom: 21px;
  }
}

.recruit-top__heading-last {
  color: #CCCCCC;
  font-family: "Montserrat", serif;
  letter-spacing: 3px;
}

@media screen and (max-width: 834px) {
  .recruit-top__heading-last {
    font-size: 10px;
    font-size: 1rem;
    line-height: 1.75;
    letter-spacing: 0.17rem;
  }
}

@media screen and (min-width: 835px) {
  .recruit-banner {
    max-width: 87.8%;
  }
}

.sec-good {
  padding: 146px 0 150px;
}

@media screen and (max-width: 834px) {
  .sec-good {
    padding: 77px 0 79px;
  }
}

.sec-good__ttl {
  margin-bottom: 77px;
}

@media screen and (max-width: 834px) {
  .sec-good__ttl {
    margin-bottom: 36px;
  }
}

.good-point {
  counter-reset: number;
  line-height: 1.95;
}

@media screen and (max-width: 834px) {
  .good-point {
    margin: 0 -15px;
    line-height: 1.75;
  }
}

.good-point__item {
  display: flex;
  flex-wrap: wrap;
}

.good-point__item:nth-child(1) .good-point__ct::after {
  left: 63px;
}

@media screen and (max-width: 834px) {
  .good-point__item:nth-child(1) .good-point__ct::after {
    left: 21px;
  }
}

.good-point__item:nth-child(2n) {
  flex-direction: row-reverse;
}

.good-point__item:nth-child(2n) .good-point__ct {
  margin-left: 0;
  margin-right: -40px;
  margin-top: 0;
  z-index: 1;
  padding-right: 120px;
  padding-left: 60px;
}

@media screen and (max-width: 834px) {
  .good-point__item:nth-child(2n) .good-point__ct {
    margin-right: auto;
    padding-right: 24px;
    padding-left: 16px;
    margin-top: -31px;
    padding-bottom: 41px;
  }
}

.good-point__item:nth-child(2n) .good-point__ct::after {
  left: 59px;
}

@media screen and (max-width: 834px) {
  .good-point__item:nth-child(2n) .good-point__ct::after {
    left: 12px;
  }
}

.good-point__item:nth-child(2n) .good-point__img {
  margin-left: -40px;
  margin-right: 0;
  margin-top: 16%;
  z-index: 2;
}

@media screen and (max-width: 834px) {
  .good-point__item:nth-child(2n) .good-point__img {
    margin-left: auto;
    margin-top: 0;
  }
}

.good-point__item:not(:last-child) {
  margin-bottom: 60px;
}

@media screen and (max-width: 834px) {
  .good-point__item:not(:last-child) {
    margin-bottom: 39px;
  }
}

.good-point__img {
  width: calc(50% + 40px);
  margin-right: -40px;
  z-index: 1;
}

@media screen and (max-width: 834px) {
  .good-point__img {
    width: 64%;
  }
}

.good-point__ct {
  width: calc(50% + 40px);
  margin-left: -40px;
  position: relative;
  z-index: 2;
  background: #FFE6F5;
  border: 1px solid #1A1A1A;
  padding: 53px 69px 86px 70px;
  margin-top: 60px;
}

@media screen and (max-width: 834px) {
  .good-point__ct {
    width: calc(100% - 35px);
    margin-left: auto;
    margin-top: -36px;
    padding: 33px 5px 50px 29px;
  }
}

.good-point__ct::before {
  counter-increment: number;
  content: "0"counter(number);
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.75;
  margin-bottom: 23px;
  display: block;
  font-family: "Montserrat", serif;
}

@media screen and (max-width: 834px) {
  .good-point__ct::before {
    font-size: 1.4rem;
    margin-bottom: 38px;
  }
}

.good-point__ct:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 48px;
  height: 1px;
  background: #1A1A1A;
  transform: rotate(-45deg);
  top: 77px;
  left: 66px;
}

@media screen and (max-width: 834px) {
  .good-point__ct:after {
    top: 52px;
    left: 23px;
    width: 35px;
  }
}

.good-point__ct-ttl {
  margin-bottom: 17px;
}

@media screen and (max-width: 834px) {
  .good-point__ct-ttl {
    margin-bottom: 26px;
  }
}

@media screen and (max-width: 374px) {
  .good-point__ct-ttl {
    font-size: 1.9rem;
  }
}

.sec-curriculum {
  padding-top: 146px;
  background: linear-gradient(180deg, #F0F6FF 57%, white 43%);
  margin-bottom: 144px;
}

@media screen and (max-width: 834px) {
  .sec-curriculum {
    padding-top: 0;
    margin-bottom: 76px;
  }
}

.sec-curriculum__ttl {
  margin-bottom: 60px;
}

@media screen and (max-width: 834px) {
  .sec-curriculum__ttl {
    margin-bottom: 38px;
  }
}

.curriculum-heading {
  margin-bottom: 54px;
}

@media screen and (max-width: 834px) {
  .curriculum-heading {
    margin-bottom: 38px;
  }
}

.curriculum-heading__ttl {
  font-size: 34px;
  font-size: 3.4rem;
  line-height: 1.7882352941;
  letter-spacing: 0.6rem;
  margin-bottom: 21px;
}

@media screen and (max-width: 834px) {
  .curriculum-heading__ttl {
    font-size: 2.2rem;
    text-align: center;
    letter-spacing: 0.4rem;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 374px) {
  .curriculum-heading__ttl {
    font-size: 2rem;
  }
}

.curriculum-heading__ttl span {
  background: #92C4FF;
  padding: 0 19px 0 24px;
  display: inline-block;
}

@media screen and (max-width: 834px) {
  .curriculum-heading .c-txt__01 {
    line-height: 1.875;
    text-align: center;
  }
}

@media screen and (max-width: 374px) {
  .curriculum-heading .c-txt__01 {
    font-size: 1.4rem;
  }
}

.curriculum-img {
  overflow-x: auto;
  margin-bottom: 100px;
}

@media screen and (max-width: 834px) {
  .curriculum-img {
    margin-bottom: 78px;
  }

  .curriculum-img img {
    max-width: 1253px;
  }
}

.curriculum-confidence {
  background: url(../img/recruit/bg-02.jpg) no-repeat center;
  background-size: 100% 100%;
  position: relative;
  padding: 94px 27px 87px;
}

@media screen and (max-width: 834px) {
  .curriculum-confidence {
    padding: 74px 27px 80px;
  }
}

.curriculum-confidence:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 1px;
  height: 80px;
  background: #1A1A1A;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
}

.curriculum-confidence__ttl {
  text-align: center;
  margin-bottom: 36px;
}

@media screen and (max-width: 834px) {
  .curriculum-confidence__ttl {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.7777777778;
    margin: 0 -10px 36px;
  }
}

@media screen and (max-width: 374px) {
  .curriculum-confidence__ttl {
    font-size: 1.4rem;
  }
}

.curriculum-confidence__list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 540px;
  gap: 60px;
  margin: 0 auto;
}

@media screen and (max-width: 834px) {
  .curriculum-confidence__list {
    grid-template-columns: 1fr;
    gap: 20px 0;
  }
}

.curriculum-confidence__list-item {
  background: white;
  position: relative;
  padding: 55px 13px 40px;
  display: grid;
  grid-template-rows: subgrid;
  gap: 0;
  grid-row: span 2;
}

@media screen and (max-width: 834px) {
  .curriculum-confidence__list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 45px 24px 30px;
  }
}

.curriculum-confidence__list-item:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 9px;
  height: 9px;
  border-left: 1px solid #1A1A1A;
  border-top: 1px solid #1A1A1A;
  top: 0;
  left: 0;
}

.curriculum-confidence__list-item:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 9px;
  height: 9px;
  border-left: 1px solid #1A1A1A;
  border-bottom: 1px solid #1A1A1A;
  bottom: 0;
  left: 0;
}

.curriculum-confidence__list-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 14px;
}

@media screen and (max-width: 834px) {
  .curriculum-confidence__list-icon {
    width: 62px;
    line-height: 0;
  }
}

.curriculum-confidence__list-icon:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 9px;
  height: 9px;
  border-right: 1px solid #1A1A1A;
  border-top: 1px solid #1A1A1A;
  top: 0;
  right: 0;
}

.curriculum-confidence__list-icon:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 9px;
  height: 9px;
  border-right: 1px solid #1A1A1A;
  border-bottom: 1px solid #1A1A1A;
  bottom: 0;
  right: 0;
}

.curriculum-confidence__list-ttl {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.36;
}

@media screen and (min-width: 835px) {
  .curriculum-confidence__list-ttl {
    text-align: center;
  }
}

@media screen and (max-width: 834px) {
  .curriculum-confidence__list-ttl {
    width: calc(100% - 76px);
    line-height: 1.45;
    margin-top: -12px;
  }
}

.sec-staff {
  padding-bottom: 160px;
}

@media screen and (max-width: 834px) {
  .sec-staff {
    padding-bottom: 74px;
  }
}

.staff-heading {
  text-align: center;
  margin-bottom: 75px;
}

@media screen and (max-width: 834px) {
  .staff-heading {
    margin-bottom: 56px;
  }
}

.staff-heading__ttl {
  margin-bottom: 51px;
}

@media screen and (max-width: 834px) {
  .staff-heading__ttl {
    margin-bottom: 39px;
  }
}

.staff-heading__subttl {
  margin-bottom: 18px;
}

@media screen and (max-width: 834px) {
  .staff-heading__subttl {
    margin-bottom: 26px;
    font-size: 2.4rem;
    letter-spacing: 3.7px;
  }
}

.staff-heading .c-txt__01 {
  line-height: 1.95;
}

@media screen and (max-width: 834px) {
  .staff-heading .c-txt__01 {
    line-height: 1.875;
  }
}

@media screen and (max-width: 374px) {
  .staff-heading .c-txt__01 {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 834px) {
  .staff-list {
    line-height: 1.75;
  }
}

.staff-list__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 1024px) {
  .staff-list__item {
    align-items: start;
  }
}

.staff-list__item:not(:last-child) {
  margin-bottom: 69px;
}

@media screen and (max-width: 834px) {
  .staff-list__item:not(:last-child) {
    margin-bottom: 53px;
  }
}

.staff-list__img {
  width: 36%;
  position: relative;
}

@media screen and (max-width: 640px) {
  .staff-list__img {
    width: 89.5%;
    margin: 0 auto 49px;
  }
}

.staff-list__img:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid #1A1A1A;
  top: 10px;
  left: 10px;
}

.staff-list__ct {
  width: 57%;
}

@media screen and (max-width: 640px) {
  .staff-list__ct {
    width: 100%;
  }
}

.staff-list__ct-ttl {
  background: #FFE6F5;
  margin-bottom: 22px;
  padding: 7px 10px 4px 20px;
  letter-spacing: 2.7px;
}

@media screen and (max-width: 834px) {
  .staff-list__ct-ttl {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.5555555556;
    padding: 16px 10px 9px;
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 640px) {
  .staff-list__ct-ttl {
    text-align: center;
  }
}

.staff-list__ct p {
  margin-right: -5px;
}

.staff-list__ct p:not(:last-child) {
  margin-bottom: 13px;
}

@media screen and (max-width: 834px) {
  .staff-list__ct p:not(:last-child) {
    margin-bottom: 16px;
  }
}

.staff-list__ct-stylist {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 2.1777777778;
  gap: 19px;
  letter-spacing: 2px;
}

@media screen and (max-width: 640px) {
  .staff-list__ct-stylist {
    justify-content: center;
  }
}

.staff-list__ct-stylist span {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.75;
  font-family: "Montserrat", serif;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: 109px;
  padding: 0 10px;
}

.staff-list__ct-stylist span:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 5px;
  height: 100%;
  border: 1px solid #1A1A1A;
  top: 0;
  left: 0;
  border-right-width: 0;
}

.staff-list__ct-stylist span:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 5px;
  height: 100%;
  border: 1px solid #1A1A1A;
  top: 0;
  right: 0;
  border-left-width: 0;
}

@media screen and (max-width: 834px) {
  .staff-list__ct-stylist span {
    min-width: 92px;
    padding: 0 10px;
  }

  .staff-list__ct-stylist span::before,
  .staff-list__ct-stylist span::after {
    height: calc(100% - 3px);
  }
}

.sec-benefit {
  background: url(../img/recruit/bg-benefit.jpg) no-repeat center/cover;
  padding: 146px 0;
}

@media screen and (max-width: 834px) {
  .sec-benefit {
    padding: 77px 0 81px;
  }
}

.sec-benefit__ttl {
  margin-bottom: 90px;
}

.benefit-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 63px 33px;
  counter-reset: number;
  line-height: 1.825;
}

@media screen and (max-width: 834px) {
  .benefit-list {
    padding: 0 17px;
    line-height: 1.75;
    gap: 52px 33px;
  }
}

@media screen and (max-width: 374px) {
  .benefit-list {
    font-size: 1.4rem;
  }
}

.benefit-list__item {
  width: calc((100% - 66px) / 3);
  position: relative;
  background: white;
  border: 1px solid #1A1A1A;
  padding: 51px 30px 28px;
  text-align: center;
}

@media screen and (max-width: 990px) {
  .benefit-list__item {
    width: calc((100% - 33px) / 2);
    padding: 55px 30px 33px;
  }
}

@media screen and (max-width: 640px) {
  .benefit-list__item {
    width: 100%;
  }
}

@media screen and (max-width: 374px) {
  .benefit-list__item {
    padding: 51px 10px 28px;
  }
}

.benefit-list__item:nth-child(-n+9)::before {
  content: "0"counter(number);
}

.benefit-list__item::before {
  counter-increment: number;
  content: counter(number);
  border: 1px solid #1A1A1A;
  background: #F0F6FF;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-size: 2rem;
  line-height: 1;
  font-weight: "Montserrat", serif;
}

.benefit-list__ttl {
  text-align: center;
  margin-bottom: 14px;
}

@media screen and (max-width: 834px) {
  .benefit-list__ttl {
    margin-bottom: 21px;
  }
}

@media screen and (max-width: 834px) and (min-width: 375px) {
  .benefit-list__ttl {
    font-size: 2.4rem;
  }
}

.benefit-list__img {
  margin-bottom: 14px;
}

.sec-message {
  background: linear-gradient(180deg, #F0F6FF 75%, white 43%);
  padding-top: 148px;
  margin-bottom: 98px;
}

@media screen and (max-width: 834px) {
  .sec-message {
    padding-top: 76px;
    margin-bottom: 80px;
    background: linear-gradient(180deg, #F0F6FF 83.5%, white 43%);
  }
}

.sec-message__ttl {
  margin-bottom: 56px;
}

.message-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 31px;
}

.message-info__img {
  width: 36%;
  position: relative;
}

@media screen and (max-width: 834px) {
  .message-info__img {
    width: 89.5%;
    margin: 0 auto 51px;
  }
}

.message-info__img:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid #1A1A1A;
  top: 10px;
  left: 10px;
}

.message-info__ct {
  width: 55%;
}

@media screen and (max-width: 834px) {
  .message-info__ct {
    width: 100%;
  }

  .message-info__ct .c-txt__01 {
    line-height: 1.75;
  }
}

.message-info__ct-ttl {
  font-size: 29px;
  font-size: 2.9rem;
  line-height: 1.3517241379;
  text-align: center;
  background: white;
  position: relative;
  padding: 36px 10px 25px;
  margin-bottom: 23px;
  letter-spacing: 4px;
}

@media screen and (max-width: 834px) {
  .message-info__ct-ttl {
    font-size: 17.92px;
    font-size: 1.792rem;
    line-height: 1.5552455357;
    padding: 29px 10px 23px;
    margin-bottom: 35px;
  }
}

@media screen and (max-width: 374px) {
  .message-info__ct-ttl {
    font-size: 1.6rem;
  }
}

.message-info__ct-ttl:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 9px;
  height: 9px;
  border-left: 1px solid #1A1A1A;
  border-top: 1px solid #1A1A1A;
  top: 0;
  left: 0;
}

.message-info__ct-ttl:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 9px;
  height: 9px;
  border-left: 1px solid #1A1A1A;
  border-bottom: 1px solid #1A1A1A;
  bottom: 0;
  left: 0;
}

.message-info__ct-ttl span:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 9px;
  height: 9px;
  border-right: 1px solid #1A1A1A;
  border-top: 1px solid #1A1A1A;
  top: 0;
  right: 0;
}

.message-info__ct-ttl span:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 9px;
  height: 9px;
  border-right: 1px solid #1A1A1A;
  border-bottom: 1px solid #1A1A1A;
  bottom: 0;
  right: 0;
}

.message-info__ct p:not(:last-child) {
  margin-bottom: 14px;
}

@media screen and (max-width: 834px) {
  .message-info__ct p:not(:last-child) {
    margin-bottom: 27px;
  }
}

.message-info__ct-name {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 2.1777777778;
  gap: 19px;
  letter-spacing: 2px;
}

@media screen and (max-width: 834px) {
  .message-info__ct-name {
    justify-content: center;
  }
}

.message-info__ct-name span {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.75;
  font-family: "Montserrat", serif;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: 109px;
  padding: 0 10px;
}

@media screen and (max-width: 834px) {
  .message-info__ct-name span {
    padding: 0 18px;
  }
}

.message-info__ct-name span:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 5px;
  height: 100%;
  border: 1px solid #1A1A1A;
  top: 0;
  left: 0;
  border-right-width: 0;
}

.message-info__ct-name span:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 5px;
  height: 100%;
  border: 1px solid #1A1A1A;
  top: 0;
  right: 0;
  border-left-width: 0;
}

@media screen and (max-width: 834px) {

  .message-info__ct-name span::before,
  .message-info__ct-name span::after {
    height: calc(100% - 3px);
  }
}

.message-bnr {
  max-width: 66.6%;
  margin-left: auto;
}

@media screen and (max-width: 834px) {
  .message-bnr {
    max-width: calc(100% + 15px);
    margin-right: -15px;
  }

  .message-bnr img {
    width: 100%;
  }
}

.sec-requirements__ttl {
  margin-bottom: 80px;
}

@media screen and (max-width: 834px) {
  .sec-requirements__ttl {
    margin-bottom: 58px;
  }
}

.requirements-tab__link {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  padding-bottom: 1px;
  margin-bottom: 56px;
  overflow-x: auto;
  position: relative;
}

.requirements-tab__link:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: auto;
  border-bottom: 1px solid #1A1A1A;
  left: 0;
  bottom: 0;
  display: block;
}

@media screen and (max-width: 834px) {
  .requirements-tab__link {
    margin-bottom: 36px;
  }
}

.requirements-tab__link-item a {
  font-size: 1.4rem;
  line-height: 2;
  background: #999999;
  color: white;
  border: 1px solid #1A1A1A;
  min-width: 149px;
  text-align: center;
  margin-bottom: -1px;
  z-index: 2;
  position: relative;
  white-space: nowrap;
  transition: all 0.3s ease-in;
}

@media screen and (max-width: 374px) {
  .requirements-tab__link-item a {
    min-width: 90px;
  }
}

.requirements-tab__link-item.active a {
  background: white;
  min-height: 39px;
  color: #1A1A1A;
  padding: 10px 10px 0;
  border-bottom-color: white;
}

.requirements-info {
  line-height: 1.575;
}

.requirements-info__item {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 26px 0 28px;
  border-bottom: 1px solid #1A1A1A;
}

.requirements-info__item:first-child {
  padding-top: 0;
}

.requirements-info__ttl {
  width: 120px;
  font-weight: 600;
}

@media screen and (max-width: 640px) {
  .requirements-info__ttl {
    width: 100%;
    margin-bottom: 14px;
  }
}

.requirements-info__ct {
  width: calc(100% - 120px);
}

@media screen and (max-width: 640px) {
  .requirements-info__ct {
    width: 100%;
  }
}

.requirements-info__dot>li {
  position: relative;
  padding-left: 10px;
}

.requirements-info__dot>li::before {
  content: "・";
  position: absolute;
  left: -4px;
  top: 0;
}

.sec-insta {
  padding: 149px 0;
}

@media screen and (max-width: 834px) {
  .sec-insta {
    padding: 80px 0;
  }
}

.sec-insta__ttl {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.96;
  text-align: center;
  letter-spacing: 1.2px;
}

@media screen and (max-width: 834px) {
  .sec-insta__ttl {
    margin-bottom: -3px;
  }
}

.sec-insta__ttl span {
  position: relative;
  padding: 0 40px;
  letter-spacing: 1.2px;
}

@media screen and (max-width: 834px) {
  .sec-insta__ttl span {
    padding: 0 20px;
  }
}

@media screen and (max-width: 374px) {
  .sec-insta__ttl span {
    padding: 0 10px;
  }
}

.sec-insta__ttl span:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 1px;
  height: 33px;
  background: #1A1A1A;
  left: 0px;
  top: -6px;
  transform: rotate(-36deg);
}

.sec-insta__ttl span:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 1px;
  height: 33px;
  background: #1A1A1A;
  right: 0;
  top: -6px;
  transform: rotate(36deg);
}

.sec-insta__subttl {
  font-size: 33px;
  font-size: 3.3rem;
  line-height: 1.7272727273;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2px 15px;
  margin-bottom: 70px;
}

@media screen and (max-width: 834px) {
  .sec-insta__subttl {
    margin-bottom: 40px;
  }
}

.sec-insta__subttl-icon {
  line-height: 0;
}

.sec-insta__subttl small {
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 1;
  color: white;
  background: #1A1A1A;
  padding: 2px 10px;
  letter-spacing: 1px;
}

@media screen and (max-width: 834px) {
  .sec-insta__subttl small {
    min-width: 230px;
    text-align: center;
    margin-top: -6px;
  }
}

.sec-insta__subttl .c-ft--en {
  letter-spacing: 3.9px;
}

.sec-insta__btn {
  max-width: 300px;
  margin: 0 auto;
  background: #92C4FF;
}

@media screen and (max-width: 834px) {
  .sec-insta__btn {
    min-height: 30px;
    max-width: 165px;
    font-size: 1rem;
  }

  .sec-insta__btn:before,
  .sec-insta__btn:after {
    display: none;
  }

  .sec-insta__btn span:before,
  .sec-insta__btn span:after {
    display: none;
  }
}

.insta-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 41px 51px;
  margin-bottom: 80px;
}

@media screen and (max-width: 834px) {
  .insta-list {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 0 13px;
    margin-bottom: 40px;
  }
}

/* ====================================================
Module
==================================================== */
/* ====================================================
Pages
==================================================== */
.main {
  margin-top: 100px;

  @include pc {
    margin-top: 140px;
  }
}

.sec-ttl {
  text-align: center;
  font-size: 20px;
  font-family: $font-mont;
  letter-spacing: 0.12em;

  @include pc {
    font-size: 32px;
  }

  span {
    display: block;
    font-size: 10px;
    margin-top: 10px;
  }
}

.page-inner {
  padding: 0 15px;

  &.w1000 {
    @include pc {
      max-width: 1000px;
      margin: 0 auto;
    }
  }
}

.pagenav {
  max-width: 335px;
  margin: 115px auto 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @include pc() {
    max-width: 560px;
    margin: 125px auto 150px;
  }

  .page-numbers {
    padding: 5px 10px;
    font-size: 13px;
    color: #000;
    transition: 0.3s;

    &:hover {
      opacity: 0.6;
    }

    &.dots {}

    &.current {
      position: relative;

      &::after {
        content: "";
        width: 10px;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: #000;
      }
    }

    &.prev,
    &.next {
      width: 61px;
      height: 10px;
      margin-top: -5px;
      border-bottom: 1px solid #808080;
      display: block;
      position: absolute;
      top: 50%;
      transition: 0.3s;

      a {
        height: 100%;
        display: block;
      }
    }

    &.prev::before,
    &.next::before {
      content: "";
      width: 10px;
      height: 1px;
      background: #808080;
      position: absolute;
      bottom: 2px;
    }

    &.prev {
      margin-right: 15%;
      left: 0;

      &::before {
        left: 0;
        transform: rotate(-35deg);
      }

      &:hover {
        left: -5px;
      }
    }

    &.next {
      margin-left: 15%;
      text-align: right;
      right: 0;

      &::before {
        right: 0;
        transform: rotate(35deg);
      }

      &:hover {
        right: -5px;
      }
    }
  }
}