$color-default: #1A1A1A;
$color-light: #fff;
$color-purple: #a089c3;

$font-mont: "Montserrat",
sans-serif;
$font-default: "游ゴシック体",
"Yu Gothic",
YuGothic,
"ヒラギノ角ゴ Pro",
"Hiragino Kaku Gothic Pro",
"メイリオ",
Meiryo,
"MS Pゴシック",
"MS PGothic",
sans-serif;